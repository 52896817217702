<template>
    <div>
        <div v-for="(item, index) in technical_list" :key="index" v-if="technical_list">
        {{ item }}
        <br>
        <button type="button" v-tooltip.bottom="'انتخاب تحلیل'" class="close" @click="saveChanges(item.technical.post_id)" data-dismiss="modal" aria-label="Close">
            <i class="uil uil-bullseye pointer"> انتخاب تحلیل</i>
        </button><br><br>
        </div>
    </div>
</template>

<script>
// onclick="$(this).parents('.modal').eq(0).toggle(); $('html').removeClass('open-alert-modal');return false;"
// این کامپوننت مودال افزودن علاقه مندی ها می باشد
export default {
    name: 'TechnicalList',
    props: ['data'],
    components: {},
    data: function () {
        return {
            technical_list : null,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.$helpers
                .makeRequest("GET", `/technical/all-list?page=1&filter=newest&time=all&market=all&bookmark=0`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.technical_list = api_response.data.response.technicals.data;
                        console.log(api_response);
                    }
                });
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        // به کلاس پرنت نیز کال بک می کند
        saveChanges(post_id) {
            this.$parent.emitData(event, { post_id: post_id }, true);
            $(this).parents('.modal').eq(0).toggle(); 
            $('html').removeClass('open-alert-modal');
        },
    },
}
</script>
